<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <SearchCouponList
            @onDataChange="(val) => (filter.search = val)"
            @searchCouponList="searchCouponList"
          />
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox v-model="valueShowOnly" v-on:change="onChkBoxChange">
            <span class="text-black">Show only product in this promotion</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div class="my-4">
        <b-table
          striped
          hover
          responsive
          :items="productList"
          :fields="columns"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(name)="data">
            <div class="text-left">
              <div class="name">{{ data.item.name }}</div>
              <div class="barcode">{{ data.item.detail }}</div>
              <div>
                {{
                  data.item.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </div>
            </div>
          </template>
          <template v-slot:cell(isCheck)="data">
            <div>
              <b-form-group>
                <b-form-checkbox-group v-model="selected">
                  <b-form-checkbox
                    :key="data.item.id"
                    :value="data.item.id"
                    @change="getChk($event, data.item.id)"
                    :disabled="valid == 1 ? data.itemisCheck : false"
                  >
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing {{ showing }} to {{ showingTo }} of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.take"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
              @change="pagination"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import SearchCouponList from "@/components/coupon/SearchCouponList";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    SearchCouponList,
    OtherLoading,
  },
  props: {
    columns: {
      required: true,
      type: Array,
    },
    productList: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    id: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
    valid: {
      required: false,
      type: Number,
    },
    isLoading: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isBusy: false,
      showing: 1,
      showingTo: 5,
      isAccept: false,
      valueShowOnly: false,
      selected: [],
      productSelect: [],
      productDelete: [],
      productSelectTrue: [],
    };
  },
  created() {
    if (this.id !== "0") {
      this.valueShowOnly = true;
    }
  },
  async mounted() {
    if (this.productList?.length > 0) {
      await this.checkedSelect();
    }
  },
  watch: {
    productList: function () {
      if (this.productList?.length > 0) {
        this.productSelect = this.selected;
        this.checkedSelect();
      } else {
      }
    },
  },
  methods: {
    checkedSelect() {
      if (this.id !== "0") {
        for (const index in this.productList) {
          if (this.productList[index].isCheck) {
            this.selected.push(this.productList[index].id);
          }
        }
      }
    },
    searchCouponList() {
      this.$emit("getProductList", this.valueShowOnly);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.$emit("filterpage", this.filter.page);
      this.$emit("getProductList", this.valueShowOnly);
    },
    getChk(checked, id) {
      if (checked) {
        if (this.productSelect?.length > 0) {
          let filterProductOut = this.productSelect.includes(id);
          if (!filterProductOut) {
            this.productSelect = [...checked];
          } else {
            let positionArry = this.productSelect.indexOf(id);
            let deleteProduct = this.productSelect.splice(positionArry, 1);
            this.productDelete = [...deleteProduct, ...this.productDelete];
          }
        } else {
          this.productSelect = [...checked];
        }
      }
      this.$emit("product", this.productSelect);
      this.$emit("delete", this.productDelete);
    },
    onChkBoxChange() {
      this.$emit("filterpage", 1);
      this.$emit("getProductList", this.valueShowOnly);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-black {
  color: #000;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
  text-align: center;
}
.name {
  font-weight: 600;
  color: #000;
}
.barcode {
  font-size: 13px;
}
</style>