<template>
  <div>
    <!-- Name & Exclusive || Discount Value -->
    <b-row>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          :isValidate="v.form.name.$error"
          isRequired
          v-model="form.name"
          :v="v.form.name"
        />
      </b-col>
      <b-col
        xs
        sm="12"
        md
        lg
        xl="6"
        :class="chkValid == 1 ? 'display-only' : ''"
      >
        <div>
          <label class="label-custom"
            >Discount <span class="text-error">*</span></label
          >
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              placeholder="Discount"
              max
              v-model="form.promotion_value"
              :disabled="chkValid == 1 ? true : false"
              oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
            />
            <div class="input-group-append">
              <button
                class="input-group-text"
                v-if="percentOrbath"
                :disabled="chkValid == 1 ? true : false"
                @click.prevent="getPercentClick(false)"
              >
                <font-awesome-icon :icon="['fas', 'percent']" class="percent" />
              </button>
              <button
                class="input-group-text"
                v-else
                :disabled="chkValid == 1 ? true : false"
                @click.prevent="getPercentClick(true)"
              >
                <span class="percent">Baht</span>
              </button>
            </div>
          </div>
          <div v-if="v.form.promotion_value.$error">
            <div class="input-error">Please input.</div>
          </div>
        </div>
        <div class="input-error" v-if="!v.form.promotion_value.isValidPromo">
          Discount value must less than or equal to 100%
        </div>
      </b-col>
    </b-row>
    <!-- Short Description -->
    <b-row>
      <b-col>
        <InputTextArea
          textFloat="Description"
          placeholder="Description"
          :value="form.short_description"
          isRequired
          :v="v.form.short_description"
          :isValidate="v.form.short_description.$error"
          v-model="form.short_description"
          rows="3"
        />
      </b-col>
    </b-row>
    <!-- Description -->
    <b-row>
      <b-col>
        <TextEditorsTiny
          textFloat="Detail"
          placeholder="Detail"
          :value="form.description"
          @onDataChange="(val) => (form.description = val)"
          isRequired
          v-model="form.description"
          :v="v.form.description"
          :isValidate="v.form.description.$error"
        />
      </b-col>
    </b-row>
    <!-- Cover Image -->
    <b-row>
      <b-col md="6">
        <UploadFile
          v-model="form.image_url"
          :value="form.image_url"
          textFloat="Cover Photo"
          placeholder="Please select a file to upload."
          format="image"
          name="thumbnail"
          accept="image/jpeg, image/png"
          text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
          isRequired
          v-on:onFileChange="onImageChange"
          :fileName="form.image_url"
          :isValidate="v.form.image_url.$error"
          :v="v.form.image_url"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div
          class="preview-box"
          v-bind:style="{
            'background-image': 'url(' + showPreview + ')',
          }"
        >
          <img
            src="@/assets/icons/loading.svg"
            class="loading"
            alt="loading"
            v-if="isLoadingImage"
          />
          <div class="text-right">
            <font-awesome-icon
              icon="times-circle"
              class="text-secondary delete-icon pointer"
              v-if="form.image_url"
              @click="onDeleteImage"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Prefix -->
    <b-row class="mt-3">
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Coupon Code"
          placeholder="Coupon Code"
          type="text"
          name="prefix"
          isRequired
          :isValidate="v.form.prefix.$error"
          v-model="form.prefix"
          :v="v.form.prefix"
          @onKeypress="onKeypressArticleNo"
        />
      </b-col>
    </b-row>
    <hr />
    <!-- Condition (Required Baht) -->
    <b-row>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Number of Privileges"
          placeholder="Number of Privileges"
          type="number"
          name="max_quantity"
          isRequired
          v-model="form.max_quantity"
          :v="v.form.max_quantity"
        />
      </b-col>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Minimum Transaction Value (Baht)"
          placeholder="Minimum Transaction Value (Baht)"
          type="number"
          name="condition"
          v-model="form.required_point"
          :v="v.form.required_point"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Valid days (After Redeemed Coupons)"
          placeholder="Valid days (After Redeemed Coupons)"
          type="number"
          name="activated"
          v-model="form.activated"
          :v="v.form.activated"
        />
      </b-col>
      <b-col>
        <b-form-group label="Coupon User Group">
          <b-form-radio-group
            class="text-black"
            v-model="form.availability"
            :disabled="chkAvailability"
            :options="availabilityList"
            name="radio-inline"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Valid From - To -->
    <b-row class="mt-2">
      <b-col xs sm="12" md lg xl="6">
        <label class="label-custom"
          >Sales Price Start Date (DD/MM/YYYYY)
          <span class="text-error">*</span></label
        >
        <div
          :class="[
            chkValid == 1 ? 'input-container input-disable' : 'input-container',
          ]"
        >
          <datetime
            type="datetime"
            :input-class="[
              v.form.start_datetime.$error
                ? 'border-error styleDatetime'
                : 'styleDatetime',
            ]"
            v-model="form.start_datetime"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            :disabled="chkValid == 1 ? true : false"
            ref="transferDateStart"
          >
          </datetime>
          <div
            :class="
              'icon-container text-right ' + id == 0 ? '' : 'cursor-default'
            "
            @click="chkValid == 1 ? true : false"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="
                chkValid == 1
                  ? 'cursor-default color-primary'
                  : 'pointer color-primary'
              "
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.start_datetime.$error" class="input-error">
          Please select date.
        </div>
      </b-col>
      <b-col xs sm="12" md lg xl="6">
        <label class="label-custom"
          >Sales Price Start Date (DD/MM/YYYYY)
          <span class="text-error">*</span></label
        >
        <div class="input-container">
          <datetime
            type="datetime"
            :input-style="styleDatetime"
            :input-class="[v.form.end_datetime.$error ? 'border-error' : '']"
            v-model="form.end_datetime"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            ref="transferDateEnd"
            :min-datetime="form.start_datetime"
          >
          </datetime>
          <div
            class="icon-container text-right"
            @click="$refs.transferDateEnd.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.end_datetime.$error" class="input-error">
          Please select date.
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <label class="label-custom"
          >Points To Be Earned (After Redeemed Coupon)
          <span class="text-error">*</span></label
        >
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="input-group-text padding-multiple"
              v-if="multiplyPoint"
              :disabled="chkValid == 1 ? true : false"
              @click.prevent="multiplyPoint = false"
            >
              <span class="icon-multiple">+</span>
            </button>
            <button
              class="input-group-text padding-multiple"
              v-else
              :disabled="chkValid == 1 ? true : false"
              @click.prevent="multiplyPoint = true"
            >
              <span class="icon-multiple">x</span>
            </button>
            <!-- <span class="input-group-text" id="basic-addon1">+</span> -->
          </div>
          <input
            type="number"
            class="form-control"
            @change="onAddPoint"
            :disabled="chkValid == 1 ? true : false"
            placeholder="Points To Be Earned (After Redeemed Coupon)"
            v-model="pointShow"
            oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
          />
        </div>
      </b-col>
      <!-- <b-col>
        <div>
          <label class="label-custom">สถานะ</label>
          <span class="px-3">
            <b-form-checkbox
              switch
              v-model="form.status"
              class="radio-active"
              size="lg"
              :disabled="isDisable"
            >
              <span class="ml-2 main-label">
                {{
                  form.status || form.status == 1 ? "เปิดใช้งาน" : "ปิดใช้งาน"
                }}
              </span>
            </b-form-checkbox>
          </span>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import UploadFile from "@/components/inputs/UploadFile";
export default {
  components: {
    InputText,
    InputTextArea,
    TextEditorsTiny,
    UploadFile,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    chkValid: {
      required: true,
      type: Number,
    },
    getPercent: {
      required: true,
      type: Boolean,
    },
    chkAvailability: {
      required: true,
      type: Boolean,
    },
    availabilityList: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    multiplyPointIcon: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    multiplyPoint() {
      if (this.multiplyPoint) {
        this.form.point = this.pointShow;
        this.form.multiply_point = 0;
      } else {
        this.form.multiply_point = this.pointShow;
        this.form.point = 0;
      }
    },
  },
  created() {
    if (this.form.image_url) this.showPreview = this.form.image_url;
    if (this.multiplyPoint) {
      this.pointShow = this.form.point;
    } else {
      this.pointShow = this.form.multiply_point;
    }
  },
  data() {
    return {
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isDisable: false,
      percentOrbath: this.getPercent,
      isLoadingImage: false,
      showPreview: "",
      multiplyPoint: this.multiplyPointIcon,
      pointShow: 0,
    };
  },
  methods: {
    onKeypressArticleNo(e) {
      if (
        (e.charCode >= 65 && e.charCode <= 90) ||
        (e.charCode >= 97 && e.charCode <= 122) ||
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode == 45
      ) {
        // return allowedEng;
      } else {
        e.preventDefault();
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.image_url = this.images;
        this.showPreview = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.image_url = "";
      this.showPreview = "";
    },
    getPercentClick(status) {
      this.percentOrbath = status;
      this.$emit("getPercent", this.percentOrbath);
    },
    getPromotionValue(event) {
      this.$emit("getPromotionValue", event.target.value);
    },
    onAddPoint(event) {
      if (this.multiplyPoint) {
        this.form.point = parseInt(event.target.value);
      } else {
        this.form.multiply_point = parseInt(event.target.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-position {
  height: 100%;
  position: relative;
}
.percent {
  font-size: 13px;
}
.text-black {
  color: #000;
}
::v-deep .border-error {
  border-color: red !important;
}
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.input-disable {
  background-color: #fafafa;
}
::v-deep .vdatetime-input.styleDatetime {
  border: none;
}
::v-deep .col-form-label {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.label-custom {
  color: #212529;
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
.input-error {
  color: red;
}
.icon-multiple {
  font-size: 20px;
}
.padding-multiple {
  padding: 0 5px;
}
</style>
